
<template>
  <RouterView :key="route?.fullPath"/>
</template>
<script>
  // import { mapActions, mapState } from "vuex";
  export default {    
    name: 'App',
    data(){
      return{
      }
    },
    computed:{
    },
    methods:{      
    },
    mounted(){
      
    },                   
  }
</script>

