import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import admin from "../modules/admin/store/admin";
export const store = new createStore({
    modules: {
        admin,
    },
    state: {},
    getters: {},
    actions: {},
    mutations: {},
    plugins: [
        createPersistedState({})
    ]
});