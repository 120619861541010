
const state = {
    auth: {
        haveAccess: false,
    },
};

const getters = {
    // isAuthenticated: state => state.auth.isLogin,
    // StateUser: state => state.user,
    // getUserLocation: state => state.userLocation
}
const mutations = {
    SET_LOGIN(state, data) {
        state.auth.haveAccess = data;
    }
}
const actions = {
    setLogin({commit}, data) {
        commit("SET_LOGIN", data);
    },   
}
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
