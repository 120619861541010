const Module = () => import("./Module.vue");

const moduleRoute = {
    path: '/admin',
    name: 'Admin',
    meta: {},
    component: Module,
    children: [
        {
            path: 'datalist',
            name: 'DataList',
            meta: { },
            component: () =>
                import('./views/DataList.vue'),
        }    
    ]
}
export default router => {
    router.addRoute(moduleRoute);
};