const Module = () => import("./Module.vue");

const moduleRoute = {
    path: '/',
    name: 'Dedumo',
    meta: {},
    component: Module,
    children: [{
        path: '',
        name: 'DedumoPage',
        meta: { },
        component: () =>
            import('./views/Dedumo.vue'),
        },
    ]
}
export default router => {
    router.addRoute(moduleRoute);
};